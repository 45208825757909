import React from 'react';

import Img from '@common/Image';
import Link from '@common/Link';

const Default = ({ data }) => {
  const {
    gatsbyImageData,
    title,
    file,
    description
  } = data;
  if (file.contentType === 'image/svg+xml' || file?.contentType === 'image/png' || file?.contentType === 'image/jpeg') {
    return (
      <Img
        image={gatsbyImageData}
        file={file}
        alt={description}
        className='mb-3'
      />
    )  
  }
  if (file.contentType === 'application/pdf') {
    return (
      <Link to={file.url}>
        {title}
      </Link>
    )
  }
  return <></>
}

export default Default
