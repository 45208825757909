import React, { createRef, useRef } from 'react';
import clsx from 'clsx';
import { Accordion } from 'react-bootstrap';

import Container from '@common/Container';
import Link from '@common/Link';

import MobileAccordionToggle from '@components/header/MobileAccordionToggle';
import DropdownLink from '@components/header/DropdownLink';
import NavColumn from '@components/header/NavColumn';

import * as styles from '@styles/modules/nav.module.scss';
import { mobileNav } from './styles.module.scss';

const MobileNav = ({ timeout, data, navigation }) => {
  const {
    loginButton,
    loginDropdown,
    otherLabel
  } = data?.contentfulLayout;
  const tabRef = useRef(navigation?.map(() => createRef()));
  const loginRef = useRef();
  const scrollIntoView = ref => {
    // Wait for open animation before scrolling
    setTimeout(() => {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }, timeout);
  }
  return (
    <div className={styles.mobileNavContainer}>
      <div id={`mobileNav`} className={mobileNav}>
        <Container>
          <Accordion className='d-flex flex-column'>
            <ul className='list-unstyled'>
              {loginDropdown &&
                <li className={styles.mobileAccordionLi}>
                  <MobileAccordionToggle eventKey='dropdown'>
                    {loginButton.copy}
                  </MobileAccordionToggle>
                  <Accordion.Collapse eventKey='dropdown' ref={loginRef} callback={() => scrollIntoView(loginRef.current)}>
                    <>
                      {loginDropdown.slice(0,1).map((link, index) => (
                        <DropdownLink key={index} link={link}/>
                      ))}
                      <span className={clsx('text-black', styles.mobileNavTitle)}>{otherLabel}</span>
                      {loginDropdown.slice(1).map((link, index) => (
                        <DropdownLink key={index} link={link}/>
                      ))}
                    </>
                  </Accordion.Collapse>
                </li>
              }
              {navigation && navigation.map((nav,index) => {
                if (nav.link) {
                  return (
                    <li key={index} className={styles.mobileAccordionLi}>
                      <Link to={nav.link} className={styles.mobileNavTitle}>
                          {nav.title}
                      </Link>
                    </li>
                  )
                }
                return (
                  <li key={index} ref={tabRef.current[index]} className={styles.mobileAccordionLi}>
                    <MobileAccordionToggle eventKey={`${index}`} callback={() => scrollIntoView(tabRef.current[index])}>
                      {nav.title}
                    </MobileAccordionToggle>
                    <Accordion.Collapse eventKey={`${index}`}>
                      <NavColumn data={[...nav?.columnLeft, ...nav?.columnMiddle, ...nav?.columnRight]}/>
                    </Accordion.Collapse>
                  </li>
                )
              })}
            </ul>
          </Accordion>
        </Container>
      </div>
    </div>
  )
}

export default MobileNav
