import React from 'react';
import clsx from 'clsx';
import { StaticImage } from 'gatsby-plugin-image';

import Modal from 'react-bootstrap/Modal';

import Embedded from '@common/Embedded';
import Img from '@common/Image';

import * as styles from '@styles/modules/common.module.scss';
import { border, shape1, shape2, shape3 } from './snapshot-report.module.scss';

const SnapshotReport = ({ data, show, close }) => {
  const {
    title,
    titleFontSize,
    titleAlignment,
    size,
    bgColour,
    body,
    images
  } = data;
  return (
    <Modal show={show} onHide={close} size={size} centered contentClassName={clsx(`overflow-hidden bg-${bgColour}`, border)}>
      <Modal.Header closeButton className={'align-items-center zIndex-100 pb-0'}>
        <Modal.Title as='h3' className={clsx('pt-1 w-100', styles.modalTitle, {
            'fs-36': titleFontSize === '36px',
            'fs-24': titleFontSize === '24px',
            'text-center': titleAlignment === 'Center',
            'text-end': titleAlignment === 'Right'
          })}>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='modal-form px-1 pb-1'>
          <p className='mb-3 text-center'>{'Download the 2022-2023 Childcare Industry Snapshot and find out!'}</p>
          <div className='d-flex flex-column-reverse flex-lg-row align-items-center'>
            <div className='col mg-lg-3'>
              {images && images.map((image, index) => (
                <Img key={index} image={image.gatsbyImageData} file={image.file} />
              ))}
            </div>
            <div className='col me-lg-6' style={{maxWidth: '357px'}}>
              {body && body.map((content, index) => (
                <Embedded key={index} data={content}/>
              ))}
            </div>
          </div>
        </div>
      </Modal.Body>
      <StaticImage className={clsx('d-none d-lg-inline-block', shape1)} src={`../../../../images/snapshot-report/shape_1.svg`} alt='' width={89} height={87}/>
      <StaticImage className={clsx('d-none d-lg-inline-block', shape2)} src={`../../../../images/snapshot-report/shape_2.svg`} alt='' width={133} height={106}/>
      <StaticImage className={clsx('d-none d-lg-inline-block', shape3)} src={`../../../../images/snapshot-report/shape_3.svg`} alt='' width={100} height={87}/>
    </Modal>
  )
}

export default SnapshotReport
