import React from 'react';
import clsx from 'clsx';

import Link from '@common/Link';

import * as styles from '@styles/modules/nav.module.scss';

const NavLink = ({ data }) => {
  const {
    bgColour,
    image,
    title,
    subtitle,
    link,
    linkCopy
  } = data;
  return bgColour ? (
    <Link 
      to={link}
      className={clsx(`d-flex flex-row`, styles.highlightLink, {[`hover-bg-${bgColour}`]:bgColour})}
    >
      {image &&
        // Couldn't use the GatsbyImg since it triggers the MouseLeave because of the images position attribute
        <img src={image?.file?.url} alt='' />
      }
      <div className={clsx(`d-flex flex-column justify-content-between`, styles.highlightCol)}>
        <div className={`d-flex flex-column`}>
          {title && <div className={styles.navTitle}>{title}</div>}
          {subtitle && <div className={clsx(`fw-normal`, styles.navSubtitle)}>{subtitle}</div>}
        </div>
        {linkCopy && <div className={styles.highlightUnderline}>{linkCopy}</div>}
      </div>
    </Link>
    ) : (
    <Link to={link} className={clsx(`d-flex flex-row`, { [styles.navMegaLink]:image })}>
      {image &&
      // Couldn't use the GatsbyImg since it triggers the MouseLeave because of the images position attribute
        <img
          src={image.file?.url}
          alt=''
          width={68}
          height={78}
          className={styles.navImg}
        />
      }
      <div className={`d-flex flex-column`}>
      {title && <div className={image ? styles.navTitle : styles.overviewLink}>{title}</div>}
        {subtitle && <div className={clsx(`fw-normal`, styles.navSubtitle)}>{subtitle}</div>}
      </div>
    </Link>
  )
}

export default NavLink
