import React from 'react';
import clsx from 'clsx';
import { StaticImage } from 'gatsby-plugin-image';

import Container from '@common/Container';
import Link from '@common/Link';
import Img from '@common/Image';
import Row from '@common/Row';

import Copyright from './Copyright';

import { bcorpLogo } from '@styles/modules/footer.module.scss';

const BottomFooter = ({ data }) => {
  const {
    bottomFooterLinks,
    socialLinks
  } = data?.contentfulLayout;
  return (
    <div className='bg-black py-3'>
      <Container>
        <Row className={`position-relative`}>
          <div className={`d-flex flex-row w-100 justify-content-center align-content-center`}>
            {socialLinks && socialLinks.map(((link, index, { length }) => (
              <Link key={index} to={link.link} className={clsx('d-flex d-lg-none align-items-center mb-1',{'me-1':(index !== length-1)})}>
                {link.image && <Img image={link.image?.gatsbyImageData} file={link.image?.file} height={38} width={38}/>}
              </Link>
            )))}
            <Link title='B Corporation' className={clsx(`d-flex`, bcorpLogo)} to={`https://www.bcorporation.net/en-us/find-a-b-corp/company/himama-inc`}>
              <StaticImage src={`../../images/footer/footer_bcorp.png`} placeholder='none' alt='' />
            </Link>
          </div>
          <Copyright links={bottomFooterLinks?.text} />
        </Row>
      </Container>
    </div>
  )
}

export default BottomFooter
