import React from 'react';

import HighlightLink from '@common/HighlightLink';

const Default = ({ data }) => {
  const {
    title,
    showTitle,
    list
  } = data;
  return (
    <>
      {(title && showTitle) && <Heading type='h2'>{title}</Heading>}
      <div className='d-flex flex-column flex-xl-row'>
        {list && list.map((feature, index) => (
          <div key={index} className='col col-xl-4 px-1 mb-3 mb-xl-0'>
            <HighlightLink data={feature}/>
          </div>
        ))}
      </div>
    </>
  )
}

export default Default
