import React,{useState , useEffect} from 'react';
import clsx from 'clsx';
import { CSSTransition } from 'react-transition-group';
import { globalHistory } from '@gatsbyjs/reach-router';
import { useDispatch } from 'react-redux';
import { toggleOverlay } from '@redux/header/actions';

import Container from '@common/Container';
import Link from '@common/Link';
import Row from '@common/Row';

import DropdownArrow from './DropdownArrow';
import NavColumn from './NavColumn';

import * as styles from '@styles/modules/nav.module.scss';

const Nav = ({ shrink, data }) => {
  const dispatch = useDispatch();
  const [navShrink , setNavShrink] = useState(shrink);
  const [navOpen, setNavOpen] = useState([]);
  const openNav = index => {
    dispatch(toggleOverlay(true));
    // Close all open nav
    const newNav = new Array(data.length).fill(false);
    newNav[index] = true;
    setNavOpen([...newNav]);
  }
  const closeNav = () => {
    // Set all to false
    setNavOpen([]);
    dispatch(toggleOverlay(false));
  }
  const blurNav = (e) => {
    // Close only if leaving a link from the mega nav
    if (!e.currentTarget.contains(e.relatedTarget)) {
      closeNav();
    }
  }
  const keyNav = (e) => {
    if (e.key === 'Escape') {
      closeNav();
    }
  }
  useEffect(() => {
    setNavShrink(shrink);
  }, [shrink]);
  useEffect(() => {
    // Adds an event listener for a route change
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        closeNav();
      }
    })
  }, []);
  return (
    <ul className={clsx(`list-unstyled mb-0 d-none d-xl-flex flex-row`, {[styles.navMegaTopShrink]:navShrink})}>
      {data.map((nav, i) => {
          if (nav.link) {
            return (
              <li key={i}>
                <Link
                  to={nav.link}
                  className={clsx(`d-flex`, styles.navLinks, {[`hover-${nav.hoverColour}`]:nav.hoverColour})}
                  >
                    {nav.title}
                </Link>
              </li>
            )
          }
          return(
            <li key={i} className={clsx({ [styles.navActive]: navOpen[i] }) } onMouseEnter={() => openNav(i)} onMouseLeave={closeNav} onBlur={blurNav} onKeyDown={keyNav}>
              <div tabIndex={0} className={clsx(`d-flex align-items-center`, styles.navLinks, {[`hover-${nav.hoverColour}`]:nav.hoverColour})} onFocus={() => openNav(i)}>
                {nav.title}
                <DropdownArrow />
              </div>
              {( nav.columnLeft || nav.columnMiddle || nav.columnRight ) &&
                <CSSTransition
                  classNames={`fade`}
                  in={navOpen[i]}
                  timeout={300}
                  unmountOnExit
                  mountOnEnter
                >
                  <div id={`megaNav`} className={clsx('bg-white', styles.navMega)}>
                    <Container>
                      <Row className={`d-flex flex-row flex-wrap align-items-stretch align-items-lg-start justify-content-between`}>
                        <NavColumn data={nav.columnLeft}/>
                        <NavColumn data={nav.columnMiddle}/>
                        <NavColumn data={nav.columnRight}/>
                      </Row>
                    </Container>
                  </div>
                </CSSTransition>
              }
            </li>
          )
        })
      }
    </ul>
  )
}

export default Nav
