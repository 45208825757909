import React from 'react';

import Heading from '@common/Heading';
import Link from '@common/Link';
import Img from '@common/Image';

const SocialLinks = ({ data }) => {
  const {
    title,
    showTitle,
    list
  } = data;
  return (
    <>
      {(title && showTitle) && <Heading type='h2'>{title}</Heading>}
      <div className='d-flex flex-row'>
        {list && list.map((link, index) => (
          <Link
            to={link.link}
            target='_blank'
            rel='noopener noreferrer'
            className={`me-3`}
            key={index}
          >
            <Img
              loading='lazy'
              image={link.image.gatsbyImageData}
              file={link.image.file}
              alt={link.image.description}
              className={'scale-1'}
            />
          </Link>
        ))}
      </div>
    </>
  )
}

export default SocialLinks
