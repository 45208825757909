import { createStore, combineReducers } from 'redux';

import headerReducer from '@redux/header/reducers';
import demoFormReducer from '@redux/demo-form/reducers';
import quizFormReducer from '@redux/quiz/reducers';
import userReducer from '@redux/user/reducers';
import academyReducer from '@redux/academy/reducers';
import cartReducer from '@redux/cart/reducers';
import blogReducer from '@redux/blog/reducers';

export const initialState = {
  user: {
    firstName: null,
    lastName: null,
    email: null,
    title: null,
    phone: null,
    country: null,
    stateProvince: null,
    centerName: null,
    stateProvince: null,
    enrollmentRange: null,
    centerEnrolment: null,
    currentSolution: null
  },
  quiz: {
    step: 1,
    redirectUrl: null,
    recommend: [],
    result: null,
    submitLead: false,
    submitResults: false
  },
  demoForm: {
    step: 1,
    redirectUrl: null,
    calendlyUrl: null,
    referringUrl: null,
    submit: false,
  },
  academy: {
    step: 1,
    submit: false
  },
  cart: {
    product: null,
    discount: null,
    currency: 'USD',
    promoCode: null,
    numberOfLicenses: null,
  },
  blog: {
    searchBar: false
  },
  header: {
    showDarkOverlay: false,
    showSideDrawer: false
  }
}

const rootReducer = combineReducers({
  header: headerReducer,
  user: userReducer,
  demoForm: demoFormReducer,
  quiz: quizFormReducer,
  academy: academyReducer,
  cart: cartReducer,
  blog: blogReducer
});

const store = createStore(rootReducer, initialState);

export default store;
