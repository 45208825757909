// extracted by mini-css-extract-plugin
export var awardImg = "careers-module--award-img--340c6";
export var awardsRow = "careers-module--awards-row--9c96d";
export var benefitPerk = "careers-module--benefit-perk--75212";
export var benefitQuote = "careers-module--benefit-quote--ac5a4";
export var benefitQuoteMargin = "careers-module--benefit-quote-margin--d964b";
export var departmentIcon = "careers-module--department-icon--a32e1";
export var departmentLink = "careers-module--department-link--5f05c";
export var employeeCard = "careers-module--employee-card--f8a86";
export var employeeCardInfo = "careers-module--employee-card-info--ecdd7";
export var galleryImage = "careers-module--gallery-image--c62a2";
export var headingBox = "careers-module--heading-box--bf93d";
export var headingImg = "careers-module--heading-img--72312";
export var playBtn = "careers-module--play-btn--d5d9a";
export var transform = "careers-module--transform--12777";
export var videoContainer = "careers-module--video-container--fd695";
export var videoWidth = "careers-module--video-width--c26da";