import React from 'react';
import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCheck } from '@fortawesome/free-solid-svg-icons';

import { splitAcademyTitle } from '@helpers/utils';

const Products = ({ data }) => {
  const {
    title,
    color
  } = data;
  return (
    <div className={clsx('w-100 p-1 rounded border border-2', {[`border-${color}`]:color})}>
      <div className='ms-auto fa-layers fa-fw'>
        <FontAwesomeIcon
          icon={faCircle}
        />
        <FontAwesomeIcon
          icon={faCheck}
          transform='shrink-7'
          inverse
        />
      </div>
      <div className='fw-semibold fs-14' style={{whiteSpace: 'pre-wrap'}}>{splitAcademyTitle(title)}</div>
      {title === 'Center Management' &&
        <div className='mt-1 fs-12'>{'*without billing'}</div>
      }
    </div>
  )
}

export default Products
