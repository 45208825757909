import React from 'react';

import Img from '@common/Image';
import Heading from '@common/Heading';

const ImageRowWrap = ({ data }) => {
  const {
    title,
    showTitle,
    images
  } = data;
  return (
    <>
      {(showTitle && title) && <Heading type='h2' className='mx-auto mb-4 col-lg-10 text-center'>{title}</Heading>}
      <div className={`d-flex flex-row align-items-center justify-content-center justify-content-lg-start flex-wrap mb-3`}>
        {images && images.map((image, index) => {
          return (
            <div key={index}>
              <Img
                image={image.gatsbyImageData}
                file={image.file}
                style={{maxWidth: `${image.file.details.image.width/2}px`, maxHeight: `${image.file.details.image.height/2}px`}}
                className={`mx-2 my-2`}
              />
            </div>
          )
        })}
      </div>
    </>
  )
}

export default ImageRowWrap
