import React from 'react';
import clsx from 'clsx';
import { useStaticQuery, graphql } from 'gatsby';

import Container from '@common/Container';
import Row from '@common/Row';
import Img from '@common/Image';
import Link from '@common/Link';

import { socialIcons } from './styles.module.scss';

const Footer = () => {
  const data = useStaticQuery(graphql`{
    contentfulLayout(title: {eq: "Summit"}) {
      ...Footer
    }
  }`);
  const { footerLogo, socialLinks } = data?.contentfulLayout;
  return (
    <footer className='py-3'>
      <Container>
        <hr/>
        <Row className='text-center justify-content-center align-items-center pt-4'>
          <Link to='/' className='text-center mb-4'>
            <Img
              loading='eager'
              image={footerLogo.gatsbyImageData}
              file={footerLogo.file}
              alt={footerLogo.description}
            />
          </Link>
          <Link to='/' className='mb-4'>{'back to lillio.com'}</Link>
          {socialLinks &&
            <Row className='justify-content-center'>
              {socialLinks.map(((link, index) => ( 
                <div key={index} className='col-auto mx-2 mx-lg-3'>
                  <Link to={link.link} className={socialIcons}>
                    {link.image && <Img image={link.image?.gatsbyImageData}  file={link.image?.file}/>} 
                  </Link>
                </div>
              )))}
            </Row>
          }
        </Row>  
      </Container>
    </footer>
  )
}

export default Footer
