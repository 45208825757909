import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import clsx from 'clsx';
import { isBrowser } from '@helpers/utils';
import { CSSTransition } from 'react-transition-group';
import { globalHistory } from '@gatsbyjs/reach-router'

import Container from '@common/Container';
import Link from '@common/Link';
import Img from '@common/Image';
import Button from '@common/Button';

import Banners from './Banners';
import Dropdown from './Dropdown';
import MobileNavButton from './MobileNavButton';
import MobileNav from './MobileNav';
import Nav from './Nav';

import * as styles from '@styles/modules/nav.module.scss';

const Header = () => {
  const data = useStaticQuery(graphql`{
    contentfulLayout(title: {eq: "Main"}) {
      ...Header
    }
  }`);
  const {
    startButton,
    loginDropdown,
    headerLogo,
    mobileHeaderLogo,
    navigation
  } = data?.contentfulLayout;
  const [shrink, setShrink] = useState(false);
  const [openMobile, setOpenMobile] = useState(false);
  const toggle = () => setOpenMobile(!openMobile);
  useEffect(() => {
    if (isBrowser()) {
      window.addEventListener('scroll', () => {
        setShrink(window.pageYOffset > 50)
      });
      window.addEventListener('resize', () => {
        if (window.innerWidth > 1200) {
          setOpenMobile(false);
        }
      });
    }
  }, []);
  useEffect(() => {
    // Adds an event listener for a route change
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        setOpenMobile(false);
      }
    })
  }, []);
  return (
    <>
      <Banners data={data} />
      <header className={clsx(`bg-white sticky-top`, styles.navHeader)}>
        <Container>
          <nav id={`mainNav`} className={clsx(styles.navHeaderContainer, {[styles.navShrink]:shrink})}>
            <div className='d-flex flex-row justify-content-between align-items-center'>
              <div className={`d-flex flex-row`}>
                {headerLogo &&
                  <Link to={`/`} className={clsx(`d-flex align-items-center`, styles.imgLogo)} title='Homepage'>
                    <Img image={headerLogo?.gatsbyImageData} file={headerLogo?.file} className={`d-none d-xl-block`}/>
                    <Img image={mobileHeaderLogo?.gatsbyImageData} file={mobileHeaderLogo?.file} className={`d-xl-none`}/>
                  </Link>
                }
              </div>
              <div className={clsx(`d-flex align-items-center`, styles.navMidHeader)}>
                {navigation && <Nav shrink={shrink} data={navigation} />}
                {startButton &&
                  <Button
                    to={startButton.link}
                    size={startButton.size}
                    variant={startButton.variant}
                    addClass={clsx('mx-25 scale-1', styles.btnStartNow, startButton.class)}
                    copy={startButton.copy}
                  />
                }
                {loginDropdown &&
                  <Dropdown data={data}/>
                }
              </div>
              <div className={`position-relative d-xl-none`}>
                <MobileNavButton onClick={toggle} open={openMobile} />
              </div>
            </div>
            <CSSTransition
              classNames='fade'
              in={openMobile}
              timeout={300}
              unmountOnExit
              mountOnEnter
              >
              <MobileNav timeout={300} data={data} navigation={navigation} />
            </CSSTransition>
          </nav>
        </Container>
      </header>
    </>
  )
}

export default Header
