export const isBrowser = () => typeof window !== 'undefined';

export const isProduction = () => process.env.NODE_ENV === 'production';

export const isEven = number => number % 2 === 0;

// Remove spaces and transform to lowercase for anchoring link and id
export const anchorLinkFormat = (string) => {
  return string.replace(/(\s|&|,)/g, '').toLowerCase();
}

export const removeWithinParentheses = (string) => {
  return string.replace(/\([^()]*\)/g, '').trim();
}

export const extractWithinParentheses = (string) => {
  const newString = string.match(/\(.*?\)/g);
  if (newString) {
    return newString[0].replace(/[(]|[)]/g, '');
  }
  return;
}

export const splitAcademyTitle = (text) => {
  if (text === 'HiMama Academy') {
    return `HiMama\nAcademy`;
  }
  return text;
}

export const removeDuplicateNodes = (list, key) => {
  const newList = [];
  for (let i = 0; i < list?.length; i++) {
    const node = list[i]?.node;
    if (newList.findIndex(x => x.node[key] === node[key]) === -1) {
      newList.push(list[i]);
    }
  }
  return newList;
}

// Convert first letter to a capital
export const toCapitalCase = (word) => word.charAt(0).toUpperCase() + word.slice(1);

// Converts seconds into a readable hour and minute format
export const toHoursAndMinutes = (totalSeconds) => {
  const totalMinutes = Math.floor(totalSeconds / 60);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  let string = '';
  if (hours) {
    string += `${hours}h`;
  }
  if (hours && minutes) {
    string += ' ';
  }
  if (minutes) {
    string += `${minutes}m`;
  }
  return string;
};

// GOOGLE ANALYTICS TRACKING CUSTOM EVENT
export const trackCustomEvent = ({
  category,
  action,
  label,
  value,
  options
}) => {
  if (isBrowser() && window.gtag) {
    const trackingEventOptions = {
      event_category: category,
      event_label: label,
      event_value: value
    }
    window.gtag(`event`, action, {...trackingEventOptions, ...options});
  }
}

export const marketoCustomEvent = ({url}) => {
  if (isBrowser() && window.Munchkin) {
    window.Munchkin.munchkinFunction('clickLink', {
        'href': url
      }
    );
  }
}

// Adds aria labels to carousel
export const enableAria = () => {
  if (!isBrowser()) return;

  const slides = window.document.getElementsByClassName('slick-slide');
  if (!slides) return;

  for (let i = 0; i < slides.length; i++) {
    slides[i].setAttribute('aria-hidden', 'false');
  }
}

export const getWordCount = text => {
  return text.match(/\w+/g).length;
};

export const timeToRead = post => {
  const AVG_WORD_PER_MIN = 250;
  const count = getWordCount(post);
  return Math.ceil(count / AVG_WORD_PER_MIN);
}
