import { initialState } from '@redux/store';

const blogReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_SEARCH':
      return {
        ...state,
        searchBar: action.payload
      }
    default:
      return state;
  }
}
export default blogReducer;
