// extracted by mini-css-extract-plugin
export var accordionArrow = "common-module--accordion-arrow--8abf1";
export var accordionArrowActive = "common-module--accordion-arrow__active--2d6ad";
export var accordionArrowLeft = "common-module--accordion-arrow-left--c1e12";
export var accordionArrowLeftActive = "common-module--accordion-arrow-left__active--89b60";
export var btnAccordion = "common-module--btn-accordion--9ebf4";
export var darkOverlay = "common-module--dark-overlay--ecb18";
export var fadeInSection = "common-module--fade-in-section--8fa83";
export var floatingPill = "common-module--floating-pill--85d2a";
export var gradient = "common-module--gradient--4c7b4";
export var gradientImg = "common-module--gradient-img--9aa82";
export var gradientOverlay = "common-module--gradient-overlay--b2957";
export var green = "common-module--green--6e4f8";
export var imgLeft = "common-module--img-left--3f01b";
export var imgRight = "common-module--img-right--502cf";
export var isVisible = "common-module--is-visible--16579";
export var loader = "common-module--loader--77af3";
export var modalTitle = "common-module--modal-title--e4ee2";
export var openVideoModal = "common-module--open-video-modal--59ab4";
export var paymentPlaceholder = "common-module--payment-placeholder--3f4d6";
export var red = "common-module--red--e39d1";
export var reverse = "common-module--reverse--d43a2";
export var spinner = "common-module--spinner--adc37";
export var statBubble = "common-module--stat-bubble--fcc28";
export var teal = "common-module--teal--47aa0";
export var textBlack = "common-module--text-black--e6c16";
export var yellow = "common-module--yellow--f8649";