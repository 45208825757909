import React from 'react';
import clsx from 'clsx';

import Heading from '@common/Heading';
import Row from '@common/Row';
import RichText from '@common/RichText';
import Button from '@common/Button';

import Products from './Products';

import * as styles from '@styles/modules/common.module.scss';

const Package = ({ data, showButton, setMinHeight }) => {
  const {
    title,
    subtitle,
    color,
    description,
    button,
    products
  } = data;
  return (
    <div className='d-flex flex-column p-3 border border-dark-grey-4 border-2 rounded position-relative overflow-hidden pop-in'>
      <div className={clsx({[`bg-${color}`]:color})} style={{position: 'absolute', height: '18px', top: '0', left: '0', right: '0'}}/>
      {subtitle &&
        <div className={clsx('px-3 fw-semibold fs-16 text-center text-uppercase rounded', styles.floatingPill, {[`bg-${color}`]:color})}>{subtitle}</div>
      }
      {title && <Heading type='h2' className='h3 fw-semibold col-11 mt-4 mt-lg-3 mb-3'>{title}</Heading>}
      <div className='mb-3' style={{minHeight: setMinHeight ? '150px':'null'}}>
        {description && <RichText p='fs-16' json={description}/>}
      </div>
      {(button && showButton) &&
        <div>
          <Button
            variant={button.variant}
            to={button.link}
            size={button.size}
            addClass={clsx('shadow scale-1 mb-3', button.class)}
            copy={button.copy}
          />
        </div>
      }
      {products &&
        <div className='d-flex flex-column flex-fill'>
          <div className='mb-2 fs-16'>{title === 'Build your own plan!' ? 'Our Solutions:':'Includes:'}</div>
          <Row className='row-cols-2 g-1'>
            {products.map((product, index) => (
              <div className='col d-flex' key={index}>
                <Products data={product}/>
              </div>
            ))}
          </Row>
        </div>
      }
    </div>
  )
}

export default Package
