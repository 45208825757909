import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import clsx from 'clsx';

import Container from '@common/Container';
import Link from '@common/Link';
import Img from '@common/Image';

import * as styles from '@styles/modules/nav.module.scss';

const Header = () => {
  const data = useStaticQuery(graphql`{
    contentfulLayout(title: {eq: "Minimal"}) {
      headerLogo {
        gatsbyImageData(
          placeholder: NONE,
          layout: CONSTRAINED,
          width: 111
        )
        file {
          url
          contentType
          details {
            image {
              height
              width
            }
          }
        }
      }
      mobileHeaderLogo {
        gatsbyImageData(
          placeholder: NONE,
          layout: CONSTRAINED,
          width: 77
        )
        file {
          url
          contentType
          details {
            image {
              height
              width
            }
          }
        }
      }
    }
  }`);
  const {
    headerLogo,
    mobileHeaderLogo
  } = data?.contentfulLayout;
  return (
    <header className={clsx(`bg-white`, styles.navHeader)}>
      <Container>
        <nav id={`mainNav`} className={clsx(styles.navHeaderContainer)}>
          <div className='d-flex flex-row justify-content-center align-items-center'>
            <div className={`d-flex flex-row`}>
              {headerLogo &&
                <Link to={`/`} className={clsx(`d-flex align-items-center`, styles.imgLogo)} title='Homepage'>
                  <Img image={headerLogo?.gatsbyImageData} file={headerLogo?.file} className={`d-none d-xl-block`}/>
                  <Img image={mobileHeaderLogo?.gatsbyImageData} file={mobileHeaderLogo?.file} className={`d-xl-none`}/>
                </Link>
              }
            </div>
          </div>
        </nav>
      </Container>
    </header>
  )
}

export default Header
