import { initialState } from '@redux/store';

const demoFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CURRENT_STEP':
      return {
        ...state,
        step: action.payload
      }
    case 'NEXT_STEP': 
      return {
        ...state,
        step: state.step + 1
      }
    case 'PREV_STEP':
      return {
        ...state,
        step: state.step - 1
      }
    case 'SET_CALENDLY':
      return {
        ...state,
        calendlyUrl: action.payload
      }
    case 'SET_REDIRECT':
      return {
        ...state,
        redirectUrl: action.payload
      }
    case 'SET_REFERRER':
      return {
        ...state,
        referringUrl: action.payload
      }
    case 'SUBMIT_FORM':
      return {
        ...state,
        submit: true
      }
    default:
      return state
  }
}
export default demoFormReducer;
