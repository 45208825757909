import React, { useState, useEffect, useRef } from 'react';
import { isBefore, isAfter } from 'date-fns';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { isBrowser } from '@helpers/utils';

import Banner from '@common/Banner';

const Banners = ({ data }) => {
  const { bottomBanners } = data?.contentfulLayout;
  const [list, setList] = useState([]);
  const [margin, setMargin] = useState([]);
  const bannerGroup = useRef();
  const handleClose = (index) => {
    // Remove index from list
    const newList = list.slice();
    newList.splice(index, 1);
    setList(newList);
  }
  const setMarginAfterResize = () => {
    setTimeout(() => {
      setMargin(bannerGroup.current?.clientHeight)
    }, 320);
  }
  useEffect(() => {
    // Filter list based on start and end dates
    const today = new Date();
    const newList = bottomBanners?.filter(x => isAfter(today, new Date(x.start)) && (isBefore(today, new Date(x.end)) || x.end == null));
    setList(newList);
  }, [bottomBanners]);
  useEffect(() => {
    // Set bottom footer margin to match space for promotions
    setMarginAfterResize()
  }, [list]);
  useEffect(() => {
    if (isBrowser()) {
      window.addEventListener('resize', () => {
        setMarginAfterResize()
      });
    }
  }, []);
  return (
    <>
      <div style={{marginBottom: `${margin}px`}}/>
      <div ref={bannerGroup} className={'d-flex flex-column fixed-bottom w-100'}>
        <TransitionGroup>
          {list && list.map((banner, index) => (
            <CSSTransition
              key={index}
              classNames='slide'
              timeout={300}
              unmountOnExit>
              <Banner data={banner} close={() => handleClose(index)}/>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
    </>
  )
}

export default Banners
