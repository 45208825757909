import React, { useState, useEffect, useRef } from 'react';
import { isBefore, isAfter } from 'date-fns';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Banner from '@common/Banner';

const Banners = ({ data }) => {
  const { topBanners } = data?.contentfulLayout || [];
  const [list, setList] = useState([]);
  const bannerGroup = useRef();
  const handleClose = (index) => {
    // Remove index from list
    const newList = list.slice();
    newList.splice(index, 1);
    setList(newList);
  }
  useEffect(() => {
    // Filter list based on start and end dates
    const today = new Date();
    const newList = topBanners?.filter(x => isAfter(today, new Date(x.start)) && (isBefore(today, new Date(x.end)) || x.end == null));
    setList(newList);
  }, [topBanners]);
  return (
    <div ref={bannerGroup} className={'d-flex flex-column position-relative'} style={{zIndex: 101}}>
      <TransitionGroup>
        {list && list.map((banner, index) => (
          <CSSTransition
            key={index}
            classNames='banners'
            timeout={300}
            unmountOnExit>
            <Banner data={banner} close={() => handleClose(index)}/>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  )
}

export default Banners
