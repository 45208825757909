import React from 'react';
import clsx from 'clsx';

import RichText from '@common/RichText';

import * as styles from '@styles/modules/footer.module.scss';

const Copyright = ({ links }) => {
  const copyright = `Copyright ${'\u00A9'}2013-${new Date().getFullYear()} Lillio.`;
  return (
    <div className={`d-flex flex-column align-items-center justify-content-center`}>
      <RichText json={links} ul={clsx('list-unstyled d-flex flex-row justify-content-center', styles.copyrightLinks)} p='mb-0' a='fs-14 text-white'/>
      <p className='mb-0 fs-14 text-white'>
        {copyright}
      </p>
    </div>
  )
}

export default Copyright
