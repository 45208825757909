export const saveQuiz = quizData => {
  return {
    type: 'SAVE_QUIZ',
    info: 'Save quiz data',
    payload: quizData
  }
}

export const quizFormNextStep = () => {
  return {
    type: 'NEXT_STEP',
    info: 'Increment current step by 1'
  }
}

export const quizFormPrevStep = () => {
  return {
    type: 'PREV_STEP',
    info: 'Decrease current step by 1'
  }
}

export const quizFormCurrentStep = step => {
  return {
    type: 'CURRENT_STEP',
    info: 'Set the current step',
    payload: step
  }
}

export const quizFormSetCalendly = url => {
  return {
    type: 'SET_CALENDLY',
    info: 'Set the calendly url',
    payload: url
  }
}

export const quizFormSetRedirect = url => {
  return {
    type: 'SET_REDIRECT',
    info: 'Set the redirect url',
    payload: url
  }
}

export const quizSetResult = title => {
  return {
    type: 'SET_RESULT',
    info: 'Set result of the quiz',
    payload: title
  }
}

export const quizFormSubmitLead = () => {
  return {
    type: 'SUBMIT_LEAD',
    info: 'Submit lead details'
  }
}

export const quizFormSubmitResults = () => {
  return {
    type: 'SUBMIT_FORM',
    info: 'Submit form'
  }
}
