import { initialState } from '@redux/store';

const academyReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CURRENT_STEP':
      return {
        ...state,
        step: action.payload
      }
    case 'NEXT_STEP': 
      return {
        ...state,
        step: state.step + 1
      }
    case 'PREV_STEP':
      return {
        ...state,
        step: state.step - 1
      }
    case 'SUBMIT_FORM':
      return {
        ...state,
        submit: true
      }
    default:
      return state
  }
}
export default academyReducer;
