import React from 'react';

import RichText from '@common/RichText';

const NavTextBox = ({ data }) => {
  const {
    title,
    showTitle,
    text
  } = data;
  return (  
    <div className={`d-flex flex-column my-3 my-xl-0`}>
      {(showTitle && title) && <div className={navTitle}>{title}</div>}
      {text &&
        <RichText
          json={text}
          ul='list-unstyled'
          p='fs-16 text-underline'
          a='text-black'
        />
      }
    </div>
  )
}

export default NavTextBox
