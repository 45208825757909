export const toggleOverlay = data => {
  return {
    type: 'TOGGLE_OVERLAY',
    info: 'Toggle dark overlay',
    payload: data
  }
}

export const toggleSideDrawer = data => {
  return {
    type: 'TOGGLE_SIDE_DRAWER',
    info: 'Toggle side drawer',
    payload: data
  }
}
