// Required for slick slider
import '@node_modules/slick-carousel/slick/slick.css';
import '@node_modules/slick-carousel/slick/slick-theme.css';
import '@node_modules/react-modal-video/scss/modal-video.scss';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/autoplay';
import 'swiper/scss/scrollbar';
import 'swiper/scss/thumbs';

import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/800.css';
import '@fontsource/poppins/900.css';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/800.css';

// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
import '@fortawesome/fontawesome-svg-core/styles.css';
// Prevent fontawesome from adding its CSS since we did it manually above:
import { config } from '@fortawesome/fontawesome-svg-core';
config.autoAddCss = false; /* eslint-disable import/first */

import '@styles/custom.scss';
import '@reach/skip-nav/styles.css';

export { default as wrapRootElement } from '@redux/wrapper';

export const onClientEntry = async () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
    console.log(`# IntersectionObserver is polyfilled!`);
  }
}

// Activates Google Optimize experiments
const activateOptimize = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: 'optimize.activate' });
}

// Save previous pathname
const previousPath = () => {
  window.locations = window.locations || [document.referrer]
  window.locations.push(window.location.href)
  window.previousPath = locations[locations.length - 2];
}

const scrollToTop = () => {
  setTimeout(() => window.scrollTo(0, 0), 200);
}

export const onRouteUpdate = () => {
  activateOptimize();
  previousPath();
  scrollToTop();
}
