import React from 'react';

import Default from '@common/embedded/button/Default';

const components = [
  {
    component: Default,
    key: 'Default'
  }
];

const ButtonEmbed = ({ data }) => {
  const { style } = data;
  const Component = components.find(x => x.key === style)?.component || Default;
  return <Component data={data}/>;
}

export default ButtonEmbed
