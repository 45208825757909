import React from 'react';

import Heading from '@common/Heading';
import Row from '@common/Row';
import LinkBorder from '@common/LinkBorder';

const BorderedLink = ({ data }) => {
  const {
    title,
    showTitle,
    list
  } = data;
  return (
    <>
      {(showTitle && title) && <Heading type='h2' className='mx-auto mb-4 col-lg-8 text-center'>{title}</Heading>}
      <Row className='row-cols-2 g-3'>
        {list.map((item, index) => (
          <div key={index} className='d-flex flex-column'>
            <LinkBorder data={item}/>
          </div>
        ))}
      </Row>
    </>
  )
}

export default BorderedLink
