import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { useSelector, useDispatch } from 'react-redux';
import { toggleOverlay, toggleSideDrawer } from '@redux/header/actions';

import * as styles from '@styles/modules/common.module.scss';

const DarkOverlay = () => {
  const header = useSelector(state => state.header);
  const blog = useSelector(state => state.blog);
  const dispatch = useDispatch();
  const closeNav = () => {
    if (header.showSideDrawer) {
      dispatch(toggleSideDrawer(false));
    }
    if (!blog.searchBar) {
      dispatch(toggleOverlay(false));
    }
  };
  return (
    <CSSTransition
      classNames='fade'
      in={header.showDarkOverlay}
      timeout={300}
      unmountOnExit
      mountOnEnter
    >
      <div className={styles.darkOverlay} onClick={closeNav} />
    </CSSTransition>
  )
}

export default DarkOverlay
