import React from 'react';
import clsx from 'clsx';

import RichText from '@common/RichText';

import * as styles from '@styles/modules/footer.module.scss';

const FooterLinksColumn = ({
  title,
  links,
  index }) => {
  return (
    <div key={index} className='d-flex flex-column px-1'>
      <p className={clsx('fw-medium text-uppercase', styles.linkMargin)}>{title}</p>
      <RichText ul='list-unstyled fw-normal fs-14 text-black' a='fw-normal text-black' p={clsx('fs-14', styles.linkMargin)} json={links}/>
    </div>
  )
}

export default FooterLinksColumn
