// extracted by mini-css-extract-plugin
export var active = "homepage-module--active--f0467";
export var btnSubscribe = "homepage-module--btn-subscribe--39cb0";
export var cta = "homepage-module--cta--1c506";
export var formContainer = "homepage-module--formContainer--dc761";
export var formPosition = "homepage-module--formPosition--05b4c";
export var headerHighlight = "homepage-module--header__highlight--3aa14";
export var leftDecal = "homepage-module--left-decal--24eaa";
export var nav = "homepage-module--nav--d3b46";
export var navDot = "homepage-module--nav-dot--17072";
export var playButton = "homepage-module--play-button--340bd";
export var rating = "homepage-module--rating--7ea10";
export var resources = "homepage-module--resources--45f63";
export var resourcesCard = "homepage-module--resourcesCard--da223";
export var reviewsCard = "homepage-module--reviews-card--eae14";
export var rightDecal = "homepage-module--right-decal--79a2f";
export var sectionFeaturesImg = "homepage-module--section-features-img--9d55f";
export var transition = "homepage-module--transition--d00ad";
export var transitionIn = "homepage-module--transitionIn--88f0b";
export var transitionOut = "homepage-module--transitionOut--dd7d4";
export var videoContainer = "homepage-module--video-container--6d5bc";
export var watchImg = "homepage-module--watch-img--a836d";