import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import clsx from 'clsx';
import { isBrowser } from '@helpers/utils';
import { CSSTransition } from 'react-transition-group';
import { globalHistory } from '@gatsbyjs/reach-router';

import Container from '@common/Container';
import Link from '@common/Link';
import Img from '@common/Image';
import Button from '@common/Button';

import Banners from '@components/header/Banners';
import Dropdown from '@components/header/Dropdown';
import MobileNavButton from '@components/header/MobileNavButton';
import MobileNav from '@components/header/MobileNav';
import Nav from '@components/header/Nav';

import * as styles from '@styles/modules/nav.module.scss';

const Header = () => {
  const data = useStaticQuery(graphql`{
    contentfulLayout(title: {eq: "Main"}) {
      ...Header
    }
    academyLayout: contentfulLayout(title: {eq: "Academy"}) {
      ...Header
    }
  }`);
  const {
    startButton,
    loginButton,
    loginDropdown,
    headerLogo,
    headerLogoMobile,
    navigation
  } = data?.contentfulLayout;
  const {
    headerLogo: secondaryHeaderLogo,
    navigation: secondaryNavigation
  } = data.academyLayout;
  const [shrink, setShrink] = useState(false);
  const [openMobile, setOpenMobile] = useState(false);
  const toggle = () => setOpenMobile(!openMobile);
  useEffect(() => {
    if (isBrowser()) {
      window.addEventListener('scroll', () => {
        setShrink(window.pageYOffset > 50)
      });
      window.addEventListener('resize', () => {
        if (window.innerWidth > 1200) {
          setOpenMobile(false);
        }
      });
    }
  }, []);
  useEffect(() => {
    // Adds an event listener for a route change
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        setOpenMobile(false);
      }
    })
  }, []);
  return (
    <>
      <Banners data={data} />
      <header className={clsx(`bg-white sticky-top`, styles.navHeader)}>
        <Container>
          <nav id={`mainNav`} className={clsx(styles.navHeaderContainer, {[styles.navShrink]:shrink})}>
            <div className='d-flex flex-row justify-content-between align-items-center'>
              <div className={`d-flex flex-row flex-grow-1`}>
                {headerLogo &&
                  <Link to={`/`} className={clsx(`d-flex align-items-center me-auto`, styles.imgLogo)} title='Homepage'>
                    <Img image={headerLogo?.gatsbyImageData} file={headerLogo?.file} />
                  </Link>
                }
                {navigation && <Nav data={navigation} />}
              </div>
              <div className={clsx(`d-flex align-items-center`, styles.navMidHeader)}>
                {startButton &&
                  <Button
                    to={startButton.link}
                    size={startButton.size}
                    variant={startButton.variant}
                    addClass={clsx('mx-25 scale-1', startButton.class)}
                    copy={startButton.copy}
                  />
                }
                {loginDropdown &&
                  <Dropdown data={data}/>
                }
                {(!loginDropdown && loginButton) &&
                  <Button
                    to={loginButton.link}
                    size={loginButton.size}
                    variant={loginButton.variant}
                    addClass={loginButton.class}
                    copy={loginButton.copy}
                  />
                }
              </div>
              <div className={`position-relative d-xl-none`}>
                <MobileNavButton onClick={toggle} open={openMobile} />
              </div>
            </div>
            <CSSTransition
              classNames='fade'
              in={openMobile}
              timeout={300}
              unmountOnExit
              mountOnEnter
              >
              <MobileNav timeout={300} data={data} navigation={[...navigation, ...secondaryNavigation]} />
            </CSSTransition>
          </nav>
          <nav id={`secondaryNav`} className={clsx('d-none d-xl-block border-top border-black', styles.navHeaderContainer, {[styles.navShrink]:shrink})}>
            <div className='d-flex flex-row justify-content-between align-items-center'>
              <div className={`d-flex flex-row flex-grow-1`}>
                {secondaryHeaderLogo &&
                  <Link to={`/academy`} className={clsx(`d-flex align-items-center me-auto`, styles.imgLogo)} title='Academy'>
                    <Img image={secondaryHeaderLogo?.gatsbyImageData} file={secondaryHeaderLogo?.file} />
                  </Link>
                }
                {secondaryNavigation && <Nav data={secondaryNavigation} />}
                <div className={clsx(`d-flex align-items-center`, styles.navMidHeader)}>
                  <Button
                    to={'/academy/signup'}
                    size='md'
                    variant='green'
                    addClass='ms-25 scale-1'
                    copy='Sign Up'
                  />
                </div>
              </div>
            </div>
          </nav>
        </Container>
      </header>
    </>
  )
}

export default Header
