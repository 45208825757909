import React from 'react';
import clsx from 'clsx';

import Img from '@common/Image';
import Link from '@common/Link';

const LinkBorder = ({ data, className, ...other }) => {
  const {
    link,
    title,
    image,
    bgColour
  } = data;
  return (
    <Link to={link} {...other} style={{paddingTop: 26, paddingBottom: 26}} className={clsx('flex-fill border border-3 px-2 border-black rounded-3 text-center text-black', className)}>
      {image &&
        <Img
          className={clsx({[`rounded bg-${bgColour}`]:bgColour})}
          loading='eager'
          image={image?.gatsbyImageData}
          file={image?.file}
          alt={image?.description}
          height={50}
          width={50}
          imgStyle={{ aspectRatio: '1 / 1', padding: 10 }}
        />
      }
      {title &&
        <p className='fs-16 fw-normal mt-1 mb-1'>{title}</p>
      }
    </Link>
  );
}

export default LinkBorder
