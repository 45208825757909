import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { globalHistory } from '@gatsbyjs/reach-router';
import { CSSTransition } from 'react-transition-group';

import Button from '@common/Button';
import DropdownLink from './DropdownLink';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';

import * as styles from '@styles/modules/nav.module.scss';

const Dropdown = ({ data }) => {
  const {
    loginButton,
    loginDropdown,
    loginDropdownLabel,
  } = data?.contentfulLayout;
  const [showDropdown, setShowDropdown] = useState(false);
  const closeDropdown = () => setShowDropdown(false);
  const openDropdown = () => setShowDropdown(true);
  const toggleDropdown = () => setShowDropdown(!showDropdown);
  useEffect(() => {
    // Adds an event listener for a route change
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        closeDropdown();
        setShowDropdown(false);
      }
    })
  }, []);
  return (
    <div className='position-relative d-none d-xl-block' onMouseLeave={closeDropdown}>
      {loginButton &&
        <Button
          variant={loginButton.variant}
          size={loginButton.size}
          addClass={clsx(loginButton.class, styles.btnLogin)}
          copy={loginButton.copy}
          onMouseEnter={openDropdown}
          onClick={toggleDropdown}
        />
      }
      <CSSTransition
        classNames='fade'
        in={showDropdown}
        timeout={300}
        unmountOnExit
        mountOnEnter
        >
        <div className={clsx('flex-column', styles.dropdownContainer)}>
          {loginDropdown.slice(0,1).map(link => (
            <DropdownLink key={link.id} link={link}/>
          ))}
          <div className='d-flex flex-row justify-content-between fw-medium mb-1'>
            <span className='fs-16 fw-medium'>{loginDropdownLabel}</span>
            <FontAwesomeIcon
              icon={faAngleUp}
              rotation={180}
            />
          </div>
          <ul className='list-unstyled mb-0'>
            {loginDropdown.slice(1).map(link => (
              <li key={link.id}>
                <DropdownLink link={link}/>
              </li>
            ))}
          </ul>
        </div>
      </CSSTransition>
    </div>
  )
}

export default Dropdown
