import React from 'react';
import clsx from 'clsx';

import Row from '@common/Row';
import RichText from '@common/RichText';
import Heading from '@common/Heading';

const ColouredTextBlocks = ({ data }) => {
  const {
    title,
    showTitle,
    list,
    bgColour
  } = data;
  return (
    <>
      {(title && showTitle) && <Heading type='h2'>{title}</Heading>}
      <Row className={clsx('m-0', {'list-text-border':!bgColour,'row-cols-md-2':list.length > 2})}>
        {list && list.map((card, index) => (
          <div key={index} className={clsx('d-flex flex-column justify-content-center ms-1 ms-md-0 mb-3 py-2', {[`border-${bgColour}`]:bgColour})} style={{borderLeft: 'solid 10px'}}>
            <p className='fs-18 fw-medium'>{card.title}</p>
            <RichText p='fs-16 mb-0' json={card.text}/>
          </div>
        ))}
      </Row>
    </>
  )
}

export default ColouredTextBlocks
