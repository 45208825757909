import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import clsx from 'clsx';
import { isBrowser } from '@helpers/utils';
import { CSSTransition } from 'react-transition-group';
import { globalHistory } from '@gatsbyjs/reach-router';
import { useSelector, useDispatch } from 'react-redux';
import { toggleSearch } from '@redux/blog/actions';

import Container from '@common/Container';
import Link from '@common/Link';
import Img from '@common/Image';
import Button from '@common/Button';
import Banners from '@components/header/Banners';
import Dropdown from '@components/header/Dropdown';
import MobileNavButton from '@components/header/MobileNavButton';
import MobileNav from '@components/blog/header/MobileNav';
import Nav from '@components/header/Nav';

import SearchButton from './SearchButton';
import SearchBar from './SearchBar';

import * as styles from '@styles/modules/nav.module.scss';

const Header = () => {
  const data = useStaticQuery(graphql`{
    contentfulLayout(title: {eq: "Blog"}) {
      ...Header
    }
  }`);
  const {
    startButton,
    loginButton,
    loginDropdown,
    headerLogo,
    mobileHeaderLogo,
    navigation
  } = data?.contentfulLayout;
  const [shrink, setShrink] = useState(false);
  const [openMobile, setOpenMobile] = useState(false);
  const blog = useSelector(state => state.blog);
  const dispatch = useDispatch();
  const toggleNav = () => {
    setOpenMobile(!openMobile);
    dispatch(toggleSearch(false))
  };
  useEffect(() => {
    if (isBrowser()) {
      window.addEventListener('scroll', () => {
        setShrink(window.pageYOffset > 50)
      });
      window.addEventListener('resize', () => {
        if (window.innerWidth > 1200) {
          setOpenMobile(false);
        }
      });
    }
  }, []);
  useEffect(() => {
    // Adds an event listener for a route change
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        setOpenMobile(false);
      }
    })
  }, []);
  return (
    <>
      <Banners data={data}/>
      <header className={clsx(`bg-white sticky-top`, styles.navHeader)}>
        <Container>
          <nav id={`mainNav`} className={clsx(styles.navHeaderContainer, {[styles.navShrink]:shrink})}>
            <div className='d-flex flex-row justify-content-between align-items-center'>
              <div className={`d-flex flex-row`}>
                {headerLogo &&
                  <Link to={`/blog`} className={clsx(`d-flex align-items-center me-auto`, styles.imgLogo)} title='Blog Homepage'>
                    <Img image={headerLogo?.gatsbyImageData} file={headerLogo?.file} imgStyle={{height: 'auto'}} className={`d-none d-xl-block`}/>
                    <Img image={mobileHeaderLogo?.gatsbyImageData} file={mobileHeaderLogo?.file} imgStyle={{maxWidth:161, height: 'auto'}} className={`d-xl-none`}/>
                  </Link>
                }
              </div>
              <div className={clsx(`d-flex justify-content-center align-items-center`, styles.navMidHeader)}>
                <Link to='/' className='d-none d-xl-block' style={{padding: '15px 15px 10px'}}>{'lillio.com'}</Link>
                <span className='d-none d-xl-block' style={{padding: '15px 15px 10px'}}>{'|'}</span>
                {navigation && <Nav data={navigation} />}
                <div className='d-none d-xl-block'>
                  <SearchButton />
                </div>
                {startButton &&
                  <Button
                    to={startButton.link}
                    size={startButton.size}
                    variant={startButton.variant}
                    addClass={clsx('mx-25 scale-1 d-none d-xl-block', styles.btnStartNow, startButton.class)}
                    copy={startButton.copy}
                  />
                }
                {loginDropdown &&
                  <Dropdown data={data}/>
                }
                {(!loginDropdown && loginButton) &&
                  <Button
                    to={loginButton.link}
                    size={loginButton.size}
                    variant={loginButton.variant}
                    addClass={loginButton.class}
                    copy={loginButton.copy}
                  />
                }
              </div>
              <div className={`d-flex flex-row position-relative d-xl-none`}>
                <SearchButton />
                <MobileNavButton onClick={toggleNav} open={openMobile} />
              </div>
            </div>
            <CSSTransition
              classNames='dropdown'
              in={blog.searchBar}
              timeout={300}
              unmountOnExit
              mountOnEnter
              >
              <SearchBar />
            </CSSTransition>
            <CSSTransition
              classNames='fade'
              in={openMobile}
              timeout={300}
              unmountOnExit
              mountOnEnter
              >
              <MobileNav timeout={300} data={data} navigation={[...navigation]} />
            </CSSTransition>
          </nav>
        </Container>
      </header>
    </>
  )
}

export default Header
