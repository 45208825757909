import React from 'react';
import clsx from 'clsx';

const Container = ({
  className,
  children,
  fullwidth,
  ...other
}) => {
  return <div className={clsx(fullwidth ? 'container-fluid p-0' : 'container', className)} {...other}>{children}</div>
}

export default Container
