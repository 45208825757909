import React, { useContext } from 'react';
import clsx from 'clsx';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { 
  mobileNavTitle,
  mobileAccordionArrow,
  mobileAccordionArrowActive,
  btnMobileAccordion 
} from '@styles/modules/nav.module.scss';

const MobileAccordionToggle = ({
  children,
  eventKey,
  callback,
  }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );
  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <button
      type='button'
      className={clsx('d-flex flex-row justify-content-between align-items-center w-100', btnMobileAccordion)}
      onClick={decoratedOnClick}
    >
      <span className={mobileNavTitle}>{children}</span>
      <FontAwesomeIcon
        className={clsx('me-2', mobileAccordionArrow,
          { [mobileAccordionArrowActive]: isCurrentEventKey })
        }
        icon={faChevronDown}
      />
    </button>
  )
}

export default MobileAccordionToggle
