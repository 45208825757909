import React from 'react';
import { useLocation } from '@gatsbyjs/reach-router';
import { Link as GatsbyLink } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const Link = ({
  children,
  to,
  activeClassName,
  className,
  partiallyActive,
  ...other
}) => {
  // This assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  // Example: /features = internal, https://... = external
  const internal = /^\/(?!\/)/.test(to);
  const { search } = useLocation();
  // Added a trim to the link to remove any whitespace from content.
  const link = to?.trim() + search;
  
  if (internal) {
    return (
      <GatsbyLink
        to={link}
        className={className}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>
    )
  }
  return (
    <OutboundLink href={link} {...other} className={className} target='_blank'>
      {children}
    </OutboundLink>
  )
}

export default Link
