import React, { useCallback } from 'react';
import clsx from 'clsx';

import { trackCustomEvent } from '@helpers/utils';

import { openVideoModal } from '@styles/modules/common.module.scss';

const OpenModalButton = ({
  videoId,
  className,
  children,
  getModalState
}) => {
  const openModal = useCallback(e => {
    e.preventDefault();
    trackCustomEvent({
      category: 'open-video',
      action: 'click',
      label: `https://youtu.be/${videoId}`
    });
    getModalState(true);
  }, []);
  return (
    <button onClick={openModal} className={clsx(className, openVideoModal)}>
      {/* <span className="sr-only sr-only-focusable">{`(opens pop-up modal)`}</span> */}
      {children}
    </button>
  )
}

export default OpenModalButton
